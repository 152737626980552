* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #ffffff;
}

.pages {
  color: #ffffff;
  text-align: center;
  font-size: 3.5rem;
  margin-top: 10%;
}

.nav-item-royal::after{
  content: "";
  display: block;
  height: 3px;
  background-color: #660C49;
  transform: scale(0,1);
  transform-origin: center;
  transition: transform .25s;
}

.nav-item-royal:hover::after {
  transform: scale(1,1);
}

.logo-royal{
  max-width: 235px;
  max-height: 110px;
}

.newcsss{
  font-size: 1rem !important;
}

.options-royal {
  text-align: left;
    line-height: 25px;
    letter-spacing: 0px;
    font-weight: 700;
    padding: 4px 20px 19px;
    margin-right: 1px;
}

.summary-left {
  float: left;
  width: 80%;
}

.chbs-summary {
  background-color: #F6F6F6;
  padding: 28px 20px 20px 20px;
}

.summary-header {
  padding-bottom: 5px;
}

.summary-fields{
  border-color: #CED3D9;
  margin-top: 15px;
  padding-bottom: 20px;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
}

.summary-fields:last-child {
  border: none;
  padding-bottom: 0px;
}

.summary-field-name {
  font-family: 'Lato';
  vertical-align: baseline;
  outline: 0;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #778591;
}

.summary-field-value {
  font-family: 'Lato';
  border: 0px;
  margin: 0px;
  padding: 0px;
  vertical-align: baseline;
  outline: 0;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #2C3E50;
}

.clear-fix::after {
  height: 0px;
  clear: both;
  content: " ";
  font-size: 0px;
  display: block;
  visibility: hidden;
}

.field-left {
  color: #556677;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  border: 0px;
  margin: 0px;
  padding: 0px;
  vertical-align: baseline;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  width: 48.36066%;
  float: left;
}

.field-right {
  color: #556677;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  border: 0px;
  margin: 0px;
  padding: 0px;
  vertical-align: baseline;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  width: 48.36066%;
  float: right;
}

.summary-price {
  margin-top: 40px;
  margin-bottom: 20px;
}

.summary-price > div span {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

.summary-price > div > span:first-child+span{
  float: right;
}

.summary-price-extra{
  float: right;
}

.price-total {
  border-color: #CED3D9;
  margin-top: 20px;
  padding-top: 20px;
  position: relative;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
}

.summary-price > .price-total>span {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  display: inline-block;
}

.summary-price > .price-total>span:first-child+span {
  border-color: #2C3E50;
  top: -1px;
  right: 0px;
  float: right;
  padding-top: 21px;
  position: absolute;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
}

.summary-right {
  float: right;
  width: 73.7705%;
}

.vehicle-filter {
  margin-bottom: 40px;
  box-shadow: 0px 18px 19px -20px rgba(0,0,0,0.1);
}

.label-group {
  background-color: #EAECEE;
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 14px 21px 15px 21px;
  color: #778591;
}

.form-field-filter {
  cursor: pointer;
  width: 33.333333%;
  float: left;
  border-color: #EAECEE;
  background-color: #ffffff;
  margin-top: -1px;
  position: relative;
  border-style: solid;
  padding: 16px 0px 0px 0px;
  border-width: 1px 1px 1px 1px;
}

.form-field-label {
  color: #778591;
  clear: both;
  margin-bottom: 7px;
  margin-left: 20px;
  display: inline-block;
  font-weight: 400px;
  font-size: 12px;
  text-transform: uppercase;
}

.number-passenger {
  display: none;
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  line-height: 31px;
  border-color: #EAECEE;
  border-width: 1px;
  border-style: solid;
  width: 100%;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
}

select:not(:-internal-list-box) {
  overflow: visible !important;
}

select {
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: flex-start;
  appearance: auto;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: field;
  margin: 0em;
  border-image: initial;
  border-radius: 0px;
}

.selected-menu {
  font-size: 18px;
  font-weight: 700;
  border: none;
  display: block;
  width: 100% !important;
  padding: 4px 20px 19px 20px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  margin-right: 0.1em;
  text-align: center;
  margin: 0px;

}

.vehicle-list {
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
}

.list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.vehicle {
  opacity: 1;
  float: left;
  max-width: 300px;
}

.vehicle-image > img {
  max-width: 100%;
  height: auto;
  display: block;
}

.vehicle-image+.vehicle-content {
  margin-left: 340px;
}

.vehicle-content-header {
  height: 100%;
  overflow: hidden;
  text-align: right;
}

.vehicle-content-header>span:first-child{
  padding-bottom: 15px;
}

.vehicle-content>.vehicle-content-header>span {
  width: 65%;
  float: left;
  display: block;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}

.vehicle-content>.vehicle-content-header>a {
  display: inline-block;
}

.button {
  padding: 14px 32px 16px 32px;
  background-color: #EAECEE;
}

.button-style-2{
  font-size: 14px;
  font-weight: 400;
  border-width: 2px;
  border-style: solid;
  border-radius: 90px;
  text-transform: uppercase;
  padding: 14px 32px 16px 32px;
}

.vehicle-content-price {
  margin-top: -5px;
  display: inline-block;
}

.vehicle-content-price > span {
  display: block;
}

.vehicle-content-price > span > span:first-child {
  line-height: 1;
  font-size: 40px;
  font-weight: 400;
  color: #660C49;
}

li{
  text-align: -webkit-match-parent;
}

.vehicle-content-description {
  height: 0px;
  overflow: hidden;
  width: 100% !important;
}

.vehicle-content-description > div {
  margin: 40px 0px 0px 0px
}

.vehicle-content-description > div > p:first-child {
  padding: 0px;
}

.vehicle-content-description > div > p:first-child {
  margin: 0px;
}

.vehicle-content-meta {
  width: 100%;
  display: table;
  margin-top: 35px;
}

.vehicle-content-meta > div {
  display: table-row;
}

.vehicle-content-meta-button {
  width: 40%;
  display: table-cell;
  vertical-align: middle;
}

.vehicle-content-meta-button > a {
  text-decoration: none;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 48px;
  text-transform: uppercase;
  box-shadow: none;
  border: 0px;
  margin: 0px;
  padding: 0px;
}

.card-royal {
  margin-bottom: 40px;
  box-shadow: 0px 18px 19px -20px rgba(0,0,0,0.1);
}

.form-field-2 {
  cursor: pointer;
  width: 50%;
  float: left;
  border-color: #EAECEE;
  background-color: #ffffff;
  margin-top: -1px;
  position: relative;
  border-style: solid;
  padding: 16px 0px 0px 0px;
  border-width: 1px 1px 1px 1px;
}

.form-field-2 > label {
  color:#778591;
  clear: both;
  margin-bottom: 7px;
  margin-left: 20px;
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

.form-field-2 > input {
  color: #2C3E50;
  font-size: 18px;
  font-weight: 700;
  border: none;
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 20px;
  line-height: 31px;
  width: 100%;
  padding-left: 50px;
  outline: none;
}

.link-royal {
  display: inline;
  color: #778591;
}

.icons-left {
  left: 10px !important;
  clear: both;
  display: block;
  margin-top: 4px;
  position: absolute;
  color: #556677;
}

.input-royal {
  padding-left: 50px !important;
  color: #2C3E50 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  border: none;
  padding-top: 0px;
  padding-bottom: 20px;
  line-height: 31px;
  width: 100%;
  outline: none !important;
  margin-left: 0px !important;
}

.form-field-royal {
  border-color: #EAECEE;
  margin-top: -1;
  position: relative;
  border-style: solid;
  padding: 16px 0px 0px 0px;
  border-width: 1px;
  background-color: #FFFFFF;
}

.form-field-royal > label {
  color:#778591;
  clear: both;
  margin-bottom: 7px;
  margin-left: 20px;
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

.royal-google-maps {
  position: relative;
  overflow: hidden;
  height: 473px;
  width: 100%;
  border: #2C3E50;
  border-style: solid;
}

.royal-info-maps {
  border-color: #EAECEE;
  border-style: solid;
  width: 100%;
  display: table;
  border-width: 0px 1px 1px 1px;
  box-shadow: 0px 18px 19px -20px rgb(0 0 0 / 10%);
}

.royal-info-maps > div {
  width: 50%;
  display: table-cell;
  padding: 30px 30px 28px 40px;
  color: #556677;
}

.royal-info-maps > div:first-child{
  border-style: solid;
  border-color: #EAECEE;
  border-width: 0px 1px 0px 0px;
}

.icon-route {
  color: #660C49;
}

.royal-info-maps > div > span:first-child {
  font-size: 40px;
  margin-top: 4;
}

.royal-info-maps > div > span {
  float: left;
}

.royal-info-maps > div > span:first-child+span {
  color: #778591;
  font-size: 12px;
  margin-left: 30px;
  min-width: 51%;
  font-weight: 400;
  text-transform: uppercase;
}

.royal-info-maps > div > span:first-child+span+span {
  color: #2C3E50;
  font-size: 26px;
  padding-top: 4px;
  font-weight: 400;
  margin-left: 15%;
  min-width: 51%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }

  .button-maps {
    text-align-last: end;
  }

  .navigation-button {
    width: 90%;
    height: 50px !important;
    font-size: 16px !important;
    border-radius: 50px !important;
  }

}

.payment-form {
  max-width: 100%;
}

.payment-header {
  font-size: 18px;
  font-weight: 700;
  margin-top: 70px;
  line-height: 30px;
  text-align: center;
  text-transform: none;
}

.select-royal{
  padding-left: 50px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  padding-top: 0px !important;
  padding-bottom: 20px !important;
  line-height: 31px !important;
  width: 100% !important;
  outline: none !important;
}

.circulo {
  width: 135px;
  height: 135px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #660C49;
  margin-top: 2%;
  margin-left: 5%;
  text-align: center;
  color: #ffffff;
  font-size: 80px;
}

.sections{
  background-color: #F6F6F6;
}

.titleSection {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  background-color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  color:#631042;
}

.titleSection-policy {
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  color:#631042;
}

.titleSection-banner {
  text-align: center;
  background-color: #631042;
  font-size: 28px;
  color:#ffffff;
  height: 57px;
  display: flex;
  align-items: center;
}

.titleSectionBook{
  margin-bottom: 100px;
  margin-top: 50px;
  text-align: center;
  background-color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  color:#631042;
}

/*
* Estilos genericos
*/

.button-booking {
  text-align: flex-end;
}

.mobile-version {
  display: none !important;
}

.card-body-chakra{
  height: 125px;
}

.css-640u4w{
  height: max-content !important;
}

.desktop-version{
  display: block !important;
}

.css-1ajgsye{
  height: 640px;
}

.title-red{
  font-size: 25px;
  color: #751668;
  text-align: center;
  font-weight: 700;
  font-style: normal;
  margin-top: 10px !important;
}

.title-gray{
  font-size: 18px;
  color: #606060;
  text-align: center;
  font-weight: 400;
  font-style: normal;
}
.card-responsive-royal{
  height: 450px;
}
/*
* Slider
*/
.css-zslww6{
  display: none !important;
}

/*
* Dropdown Idioma
*/

.items-drop {
  justify-content: space-evenly;
}

.item-icon-drop{
  height: 25px;
}

/*
* Vehiculos
*/
.chbs-list-reset{
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.chbs-vehicle-list>ul>li {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
}

.chbs-vehicle-image {
  opacity: 1;
  float: left;
}

.chbs-vehicle-content {
  margin-left: 340px;
}

.chbs-vehicle-content-header {
  height: 100%;
  overflow: hidden;
  text-align: right;
}

.chbs-vehicle .chbs-vehicle-content>div.chbs-vehicle-content-header>span {
  width: 65%;
  float: left;
  display: block;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}

.chbs-vehicle-content>div.chbs-vehicle-content-header>.chbs-button {
  padding: 14px 32px 16px 32px;
  display: inline-block;
  background-color: #EAECEE;
}

.chbs-button.chbs-button-style-1, .chbs-button.chbs-button-style-2, .chbs-button.chbs-button-style-3 {
  font-size: 14px;
  font-weight: 400;
  border-width: 2px;
  border-style: solid;
  border-radius: 90px;
  text-transform: uppercase;
  padding: 14px 32px 16px 32px;
  text-decoration: none;
}

.chbs-vehicle-content>.chbs-vehicle-content-price {
  margin-top: 5px;
  display: inline-block;
}

.chbs-vehicle-content>.chbs-vehicle-content-price>span>span:first-child {
  line-height: 1;
  font-size: 40px;
  font-weight: 400;
  color: #660C49;
  display: block;
}

.chbs-vehicle .chbs-vehicle-content>.chbs-vehicle-content-description {
  height: 0px;
  overflow: hidden;
  width: 100% !important;
}

/*
* Content (More Infor and Icons)
*/
.chbs-vehicle-content>.chbs-vehicle-content-meta {
  width: 100%;
  display: table;
  margin-top: 35px;
}

.chbs-vehicle-content>.chbs-vehicle-content-meta>div {
  display: table-row;
}

.chbs-vehicle-content>.chbs-vehicle-content-meta span {
  margin-right: 20px;
}

.chbs-show-more-button {
  display: inline-block;
  margin-right: 20px;
  color: #CED3D9;
}

.chbs-vehicle-content>.chbs-vehicle-content-meta>div>div {
  width: 40%;
  display: table-cell;
  vertical-align: middle;
}

.chbs-meta-icon-people {
  font-size: 48px;
  margin-top: -12px;
  color: #631042;
}

.chbs-vehicle-content>.chbs-vehicle-content-meta span {
  margin-right: 20px;
  color: #CED3D9;
}

.chbs-circle {
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 9999em;
  display: inline-block;
  border-color: #631042;
}

.chbs-vehicle-list>ul>li:last-child {
  border-bottom-width: 0px;
}

/*
* Extras
*/

.chbs-booking-extra{
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
}

.chbs-booking-extra-header {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  margin-bottom: 40px;
  display: flex;
}

.chbs-state-open {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  height: auto;
  overflow: hidden;
}

.chbs-booking-extra-list {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
}

.chbs-list-reset {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  list-style: none;
  width: 100%;
  display: table;
}

.chbs-booking-extra-list-quantity-enable {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  display: table-row-group;
}

.chbs-column-1 {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  border-style: solid;
  display: table-cell;
  vertical-align: middle;
  padding: 20px 0px 20px 0px;
  border-width: 1px 0px 0px 1px;
  border-color: #EAECEE;
  border-bottom-width: 1px;
  width: 65%;
  border-left-width: 0px;
  padding-right: 20px;
}

.chbs-column-1-right {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
}

.chbs-booking-form-extra-name {
  font-family: 'Lato';
  list-style: none;
  outline: 0;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-right: 20px;
  color: #2C3E50;
}

.chbs-booking-form-extra-price{
  font-family: 'Lato';
  list-style: none;
  outline: 0;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  display: inline-block;
  color: #660C49;
}

.chbs-booking-form-extra-description{
  font-family: 'Lato';
  list-style: none;
  outline: 0;
  box-sizing: border-box;
  display: block;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  color: #778591;
}

.chbs-column-2 {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  border-style: solid;
  display: table-cell;
  vertical-align: middle;
  padding: 20px 0px 20px 0px;
  border-width: 1px 0px 0px 1px;
  border-color: #EAECEE;
  border-bottom-width: 1px;
  width: 15%;
  position: relative;
}

.chbs-form-field {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  margin-top: -1px;
  padding: 16px 0px 0px 0px;
  background-color: #FFFFFF;
  border: none;
  padding-top: 0px;
  position: static;
}

.chbs-form-field>label {
  clear: both;
  margin-bottom: 7px;
  margin-left: 20px;
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}

.chbs-quantity-section-button {
  list-style: none;
  outline: 0;
  box-sizing: border-box;
  font-family: 'chbs-meta-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  transition: all 150ms ease;
  z-index: 2;
  width: 30px;
  font-size: 16px;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  position: absolute;
  border-style: solid;
  color: #660C49;
  border-color: #EAECEE;
  right: 0px;
  height: 30px;
  display: block;
  margin-bottom: -30px;
  border-width: 1px 0px 1px 1px;
}

.chbs-form-field , .chbs-main .chbs-form-field select, .chbs-main .chbs-form-field textarea {
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 50px;
  padding-bottom: 20px;
  line-height: 31px;
  border: none;
}

.chbs-column-3 {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  border-style: solid;
  display: table-cell;
  vertical-align: middle;
  padding: 20px 0px 20px 0px;
  border-width: 1px 0px 0px 1px;
  border-color: #EAECEE;
  border-bottom-width: 1px;
  width: 20%;
  text-align: right;
  border-right-width: 0px;
  }

.chbs-quantity-section {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  list-style: none;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  position: relative;
}

.chbs-layout-column-right {
  color: #666;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 300;
  outline: 0;
  line-height: 150%;
  box-sizing: border-box;
  float: left;
  width: 80%;
}

.summary-info {
  max-width: 100%;
  height: auto;
  display: block;
}

.chbs-main .chbs-gratuity-section a.chbs-button.chbs-button-style-2, .chbs-main .chbs-coupon-code-section a.chbs-button.chbs-button-style-2 {
  float: right;
  margin-top: 10px;
  display: inline-block;
}

.payment-button{
  text-align-last: flex-end;
}

@media only screen and (min-width: 390px){
  .landing {
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url('https://royalpickupsrc.s3.amazonaws.com/Slider/Slide.webp');
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}



/*
  Celulares pequeños
*/

@media only screen and (max-width: 600px) {
  /**
  Estilos Cards
  **/
  .circulo {
    width: 70px;
    height: 70px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #660C49;
    margin-left: 2%;
    text-align: center;
    color: #ffffff;
    font-size: 55px;
  }
  .card-title{
    text-align: center;
    color: #631042;
    font-weight: bold;
    font-size: 20px;
  }
  .css-2etcvl{
    display: none !important;
  }
  .css-640u4w{
    height: max-content !important;
  }

  .mobile-version{
    display: block !important;
  }

  .card-body-chakra{
    height: 155px;
  }

  .desktop-version{
    display: none !important;
  }

  /*
  *Estilos del Card
  */
  .css-1ajgsye{
    height: 400px !important;
  }

  .titleSection {
    text-align: center;
    background-color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    color:#631042;
  }

  .titleSection-banner {
    text-align: center;
    background-color: #631042;
    font-size: 20px;
    color:#ffffff;
    height: 57px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .chbs-summary {
    margin-bottom: 40px;
  }

  .chbs-coupon-code-section {
    margin-top: 40px;
  }

  .summary-price {
    margin-bottom: 40px;
  }

  .payment-button{
    text-align-last: center;
  }

  .btn-coupon{
    text-align-last: center;
  }

  .navbar-brand {
    max-width: 150px !important;
  }

  .captions-royal {
    font-size: 20px !important;
    color: #FFFFFF;
    font-weight: lighter;
  }

  .subtitle-captions {
    font-size: 20px !important;
    color: #FFFFFF;
    font-weight: bold;
  }

  .text-captions {
    padding-bottom: 35px;
  }

  .input-royal {
    padding-left: 35px !important;
    color: #2C3E50 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    border: none;
    padding-top: 0px;
    padding-bottom: 20px;
    line-height: 31px;
    width: 100%;
    outline: none !important;
    margin-left: 0px !important;
  }

  .chbs-vehicle-content-price {
    text-align: center !important;
  }

  .chbs-vehicle-carname {
    text-align: center !important;
    width: 100% !important;
  }

  .title-card-vehicles{
    display: flex;
    place-content: center;
  }

  .landing {
    padding-top: 50px;
    padding-bottom: 50px;
    background-image: url('https://royalpickupsrc.s3.amazonaws.com/Slider/Slide.webp');
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

}

/*
  Celulares promedio
*/
@media (min-width: 481px) and (max-width: 767px) {

  .captions-royal {
    font-size: 20px !important;
    color: #FFFFFF;
    font-weight: lighter;
  }

  .subtitle-captions {
    font-size: 20px !important;
    color: #FFFFFF;
    font-weight: bold;
  }

  .mobileSlider{
    display: none;
  }
  .cardBooks{
    display: none;
  }
  .destinations-btn{
    display: none;
  }

  .desktop-version{
    display: none !important;
  }
    /*
  *Estilos del Card
  */
  .card-responsive-royal{
    height: 450px !important;
  }

  .titleSection {
    text-align: center;
    background-color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    color:#631042;
  }

  .titleSection-banner {
    text-align: center;
    background-color: #631042;
    font-size: 20px;
    color:#ffffff;
    height: 57px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .chbs-summary {
    margin-bottom: 40px;
  }

  .chbs-coupon-code-section {
    margin-top: 40px;
  }

  .summary-price {
    margin-bottom: 40px;
  }

  .mobile-version{
    display: block !important;
  }

  .btn-coupon{
    text-align-last: center;
  }

  .chbs-vehicle-content-price {
    text-align: center !important;
  }

  .chbs-vehicle-carname {
    text-align: center !important;
    width: 100% !important;
  }

  .title-card-vehicles{
    display: flex;
    place-content: center;
  }

}

/*
Tabletas
*/
@media (min-width: 768px) and (max-width: 1024px){

  .mobileSlider{
    display: none;
  }

  .destinations-btn{
    display: none;
  }
  /*
  * Nuevos responsive
  */
  .image-mobile{
    display: none;
  }
    /*
  *Estilos del Card
  */
  .card-responsive-royal{
    height: 450px !important;
  }

  .titleSection {
    text-align: center;
    background-color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    color:#631042;
  }

  .titleSection-banner {
    text-align: center;
    background-color: #631042;
    font-size: 20px;
    color:#ffffff;
    height: 57px;
    display: flex;
    align-items: center;
  }

  .chbs-summary {
    margin-bottom: 40px;
  }

  .summary-price {
    margin-bottom: 40px;
  }

  .payment-button{
    text-align-last: center;
  }

  .button-navigate{
    justify-content: space-between;
    display: flex
  }

  .landing {
    background-image: url('https://royalpickupsrc.s3.amazonaws.com/Slider/Slide.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
    background-size: cover;
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-feature-settings: normal;
    line-height: inherit;
    cursor: auto;
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
    position: relative;
    display: flex;
    min-height: 100%;
    align-items: flex-end;
    flex: none;
    justify-content: flex-start;
    padding-right: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }


}

/*
  Laptops grandes
*/
@media (min-width: 1025px) and (max-width: 1280px){

  .title-red{
    font-size: 25px;
    color: #751668;
    text-align: center;
    font-weight: 700;
    font-style: normal;
    margin-top: 10px !important;
  }

  .title-gray{
    font-size: 18px;
    color: #606060;
    text-align: center;
    font-weight: 400;
    font-style: normal;
  }

  .carousel{
    width: 100% !important;
  }
  .cardBooks{
    display: block;
  }

  .mobileSlider{
    display: none;
  }

  .bannerWeb{
    display: none;
  }

  /*
  * Nuevos responsive
  */
  .image-mobile{
    display: none;
  }

  .chbs-summary {
    margin-bottom: 40px;
  }

  .button-navigate{
    justify-content: space-between;
    display: flex
  }

  .padding-button {
    padding: 20px;
  }

  .landing {
    background-image: url('https://royalpickupsrc.s3.amazonaws.com/Slider/Slide.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
    background-size: cover;
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-feature-settings: normal;
    line-height: inherit;
    cursor: auto;
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
    position: relative;
    display: flex;
    min-height: 100%;
    align-items: flex-end;
    flex: none;
    justify-content: flex-start;
    padding-right: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }


}
/*
  Laptops
*/
@media (min-width: 1281px) {

  .padding-button {
    padding: 20px;
  }

  .title-red{
    font-size: 25px;
    color: #751668;
    text-align: center;
    font-weight: 700;
    font-style: normal;
    margin-top: 10px !important;
  }

  .title-gray{
    font-size: 18px;
    color: #606060;
    text-align: center;
    font-weight: 400;
    font-style: normal;
  }

  .carousel{
    width: 100% !important;
  }

  .cardBooks{
    display: block;
  }

  .mobileSlider{
    display: none;
  }

  .bannerWeb{
    display: none;
  }

  /*
  * Nuevos responsive
  */
  .image-mobile{
    display: none;
  }

  .card-title{
    text-align: center;
    color: #631042;
    font-size: 22px;
    font-weight: bold;
  }

  .chbs-summary {
    margin-bottom: 40px;
  }

  .button-navigate {
    display: flex !important;
    justify-content: space-between;
  }

  .landing {
    background-image: url('https://royalpickupsrc.s3.amazonaws.com/Slider/Slide.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
    background-size: cover;
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-feature-settings: normal;
    line-height: inherit;
    cursor: auto;
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
    position: relative;
    display: flex;
    min-height: 100%;
    align-items: flex-end;
    flex: none;
    justify-content: flex-start;
    padding-right: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }


}



/*
* Estilos del selector
*/

.bs-stepper{
  padding-bottom: 35px !important;
}

.step-trigger {
  cursor: default !important;
  padding-bottom: 5px !important;
  text-align: -webkit-center !important;
  display: grid !important;
  place-items: center !important;
}

.step-trigger:hover {
  background:none !important;
}

.bs-stepper-circle{
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  align-items: center !important;
  background-color: #EAECEE !important;
}



/*
* Estilos del Card de auto
*/

.chbs-vehicle-content-meta-info {
  text-align: right;
  padding-top: 5px !important;
}

.chbs-vehicle-content-meta-info>div>div>div>span.chbs-circle {
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 28px;
  margin-left: 15px;
  margin-right: 15px;
}

.chbs-show-more-button {
  margin-right: 20px;
  color: #CED3D9;
}

.chbs-vehicle-carname {
  width: 65%;
  float: left;
  display: block;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  align-self: center;
}

.chbs-vehicle-content-price {
  margin-top: 5px;
  display: inline-block;
  padding: 10px !important;
}

.chbs-vehicle-content-price>span>span:first-child {
  line-height: 1;
  font-size: 40px;
  font-weight: 400;
  color: #660C49;
  display: block;
}

.no-board{
  border: none !important;
  border-style: none !important;
}

button[aria-selected="true"]{
  background-color: #631042;
}

.chakra-accordion{
  border-style: none !important;
}

.active-step{
  background-color: #660C40 !important;
}

.css-q8sxkv:focus-visible{
  box-shadow: none !important;
}

.selected-button {
  color: #ffffff;
  background: #631042;
}

.card-responsive-royal:hover {
  transition: transform .5s;


}

.card-responsive-royal::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
  content: '';
  opacity: 0;
  z-index: -1;
}
.card-responsive-royal:hover, :focus {
  transform: scale3d(1.006, 1.006, 1);
}
.card-responsive-royal:hover::after, :focus::after {
  opacity: 1;
}

.my-selected:not([disabled]) {
  font-weight: bold;
  border: 2px solid currentColor;
  border-color: #f6bc01;
  color: #631042;
  background-color: #f6bc01 !important;
}
.my-selected:hover:not([disabled]) {
  border-color: #f6bc01;
  color: #631042;
  background-color: #f6bc01 !important;
}
.my-today {
  font-weight: bold;
  font-size: 140%;
  color: red;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url('https://royalpickupsrc.s3.amazonaws.com/royalLoader.webp') no-repeat center;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.PhoneInputInput {
  outline: none !important;
}


.ant-picker-input > input {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #2C3E50;
}




.react-ios-time-picker {
  margin-bottom: 50px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 11px 15px #0005;
}

.react-ios-time-picker-transition {
  animation: fade-in 150ms ease-out;
}

@keyframes fade-in {
  0% {
     transform: translateY(150px);
     opacity: 0;
  }
  100% {
     transform: translateY(0);
     opacity: 1;
  }
}

.react-ios-time-picker-container {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #f7f7f7;
  width: 220px;
  overflow: hidden;
  /* border-radius: 0 0 15px 17px; */
  padding: 20px 0;
  /* box-shadow: inset 0px 0px 5px 0px rgba(255, 159, 10, 0.5); */
  /* box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%),
   0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%); */
}

.react-ios-time-picker-hour {
  position: relative;
  width: 50px;
  overflow: hidden;
  z-index: 100;
  margin-right: 5px;
}

.react-ios-time-picker-minute {
  position: relative;
  width: 50px;
  overflow: hidden;
  z-index: 100;
  margin-left: 5px;
}

.react-ios-time-picker-hour-format {
  position: relative;
  width: 40px;
  overflow: hidden;
  z-index: 100;
}

.react-ios-time-picker-fast {
  transition: transform 700ms cubic-bezier(0.13, 0.67, 0.01, 0.94);
}

.react-ios-time-picker-slow {
  transition: transform 600ms cubic-bezier(0.13, 0.67, 0.01, 0.94);
}

.react-ios-time-picker-selected-overlay {
  position: absolute;
  border-radius: 6px;
  background-color: #631042;
  pointer-events: none;
  margin: 0 10px;
  left: 0;
  right: 0;
  z-index: 1;
  /* box-shadow: inset 0px 0px 2px 0px rgba(255, 159, 10, 0.3); */
}

.react-ios-time-picker-top-shadow {
  position: absolute;
  top: 0;
  width: 100%;
  background: #0009;
  background: linear-gradient(180deg, #0009 0%, #1c1c1c 100%);
}

.react-ios-time-picker-bottom-shadow {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #0009;
  background: linear-gradient(0deg, #0009 0%, hsla(0, 0%, 11%, 1) 100%);
}

.react-ios-time-picker-cell-hour {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  transition: all 100ms linear;
}
.react-ios-time-picker-cell-minute {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  transition: all 100ms linear;
}
.react-ios-time-picker-cell-hour-format {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  transition: all 100ms linear;
}

.react-ios-time-picker-cell-inner-hour {
  width: fit-content;
  height: 100%;
  transition: all 100ms linear;
  cursor: pointer;
  border-radius: 7px;
  line-height: 35px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: #666;
  padding: 0 10px;
}

.react-ios-time-picker-cell-inner-hour-format {
  width: fit-content;
  height: 100%;
  transition: all 100ms linear;
  cursor: pointer;
  border-radius: 7px;
  line-height: 35px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: #6a6a6b;
  padding: 0 10px;
}

.react-ios-time-picker-cell-inner-minute {
  width: fit-content;
  height: 100%;
  transition: all 100ms linear;
  cursor: pointer;
  border-radius: 7px;
  line-height: 35px;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #6a6a6b;
  padding: 0 10px;
}

.react-ios-time-picker-cell-inner-hour:hover,
.react-ios-time-picker-cell-inner-minute:hover,
.react-ios-time-picker-cell-inner-hour-format:hover {
  background-color: #ff9d0ac9;
  color: white;
}

.react-ios-time-picker-cell-inner-selected {
  /* font-weight: 500; */
  color: #f7f7f7;
  font-size: 16px;
}

.react-ios-time-picker-cell-inner-hour-format-selected {
  font-weight: 400;
  color: #f7f7f7;
}

.react-ios-time-picker-btn-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #EAECEE;
  z-index: 100;
}

.react-ios-time-picker-btn {
  padding: 10px 15px;
  font-size: 13px;
  transition: all 150ms linear;
  font-weight: 500;
  z-index: 1;
}

.react-ios-time-picker-btn:hover {
  opacity: 0.6;
}

.react-ios-time-picker-btn-cancel {
  font-size: 12px;
  font-weight: 300;
}

.react-ios-time-picker-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99998;
}

.react-ios-time-picker-popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.react-ios-time-picker-input {
  cursor: text;
  padding: 5px 10px;
  border-radius: 5px;
}

.react-ios-time-picker-colon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #f7f7f7;
  position: relative;
  z-index: 100;
  font-weight: 600;
}

.react-ios-time-picker-cell-inner-hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.react-ios-time-picker-hour-format-transition {
  transition: transform 100ms ease-out;
}


/*
* Estilos Bootstrap
*/

.carousel-caption {
  bottom: 20% !important;
}

.carousel-caption > h1 {
  font-size: 2.5rem;
}

.carousel-caption > p {
  font-size: 2.5rem;
}

.divider{
  border: 1px solid #390524;
}

.accordion-button {
  color: #390524 !important;
  font-weight: bold;
}

.list-bullet {
  padding-left: 20px;
}

.image-destinations {
  align-self: center;
  padding-top: 5%;
  width: 90% !important;
}

.link-text {
  color: blue;
  white-space: nowrap;
}

.captions-royal {
  font-size: 30px;
  color: #FFFFFF;
  font-weight: lighter;
}

.subtitle-captions {
  font-size: 30px;
  color: #FFFFFF;
  font-weight: bold;
}

.text-captions {
  text-align: center;
  align-self: center;
}

.links-footer:hover {
  color:#4a90e2;
  cursor: pointer;
}

.icons-footer {
  margin-right: 8px;
}

.button-footer {
  color: #FFFFFF !important;
  display: flex !important;
  align-items: center !important;
}

.button-footer:hover {
  cursor: pointer;
  color:#4a90e2;
}

.card-buttons {
  display: flex;
  justify-content: center;
}

.chbs-button-style-book{
  font-size: 12px;
  border-radius: 11px;
  text-transform: uppercase;
  padding: 7px 32px 7px 32px;
  text-decoration: none;
}

.background-banner {
  background-color: #585858;
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
}

.name-carousel {
  margin-top: 10px;
  font-weight: bold;
  font-size: 25px;
  color: #390524;
  padding-bottom: 25px;
}
.name-cards {
  margin-top: 5px;
  font-weight: bold;
  font-size: 18px;
  color: #390524;
  padding-bottom: 5px;
}

.royal-border-booking {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  color: white;
  background-color: #660C49;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.border-bottom-left {
  border-bottom-left-radius: 25px;
}

.border-bottom-right {
  border-bottom-right-radius: 25px;
}

.newStyle {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-feature-settings: normal;
    line-height: inherit;
    cursor: auto;
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
    position: relative;
    display: flex;
    min-height: 100%;
    align-items: flex-end;
    flex: none;
    justify-content: flex-start;
    padding-right: 0;
    padding-top: 142px;
    padding-bottom: 132px;
}

.imageBanner {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    font-feature-settings: normal;
    line-height: inherit;
    cursor: auto;
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
    display: block;
    vertical-align: middle;
    max-width: 100%;
    aspect-ratio: 2/1;
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0px;
    color: transparent;
}

.form {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  line-height: inherit;
  cursor: auto;
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0;
  max-width: 516px;
  padding: 3rem;
}

.title-card-vehicles{
  display: flex;
}

.carousel-control-prev {
  display: none !important;
}

.carousel-control-next {
  display: none !important;
}